/**
 * Created by Dawid on 2017-03-29.
 */

function subnavigation(){
    $('.submenu li a').click(function(e){
       e.preventDefault();
       var no = $(this).data('target');
       $('.submenu li a').removeClass('active');
       $('.sublayout').hide();
       $('.submenu-'+ no).addClass('active');
       $('.sublayout-'+ no).fadeIn();
        $('.title').hide();
        $('.title-'+ no).fadeIn();
    });
}
function subnavigationMobile(){
    $('.submenu-mobile li a').click(function(e){
        e.preventDefault();
        var no = $(this).data('target');
        $('.submenu li a').removeClass('active');
        $('.sublayout').hide();
        $('.submenu-'+ no).addClass('active');
        $('.sublayout-'+ no).fadeIn();

        $('.navbar_btn').removeClass('on');
        $('.header').removeClass('expand');
        $('.c-nav-main').slideUp();
        $('.title-menu').fadeIn();
        document.getElementById('navbar_btn').setAttribute('aria-expanded', 'true');
        $('.title').hide();
        $('.title-'+ no).fadeIn();
    });
}

function goToSubmenu(no){
    $('.submenu li a').removeClass('active');
    $('.sublayout').hide();
    $('.submenu-'+ no).addClass('active');
    $('.sublayout-'+ no).fadeIn();
    $('.title').hide();
    $('.title-'+ no).fadeIn();
}

function footerLinks(){
    var url = window.location.href;
    var link = url.substring(url.indexOf("#"));

    if(link == '#kontakt_austellung'){
        goToSubmenu(3);
    }
    else if(link == '#kontakt_stiftung'){
        goToSubmenu(5);
    }
    else if(link == '#kontakt_kirchgemeinde'){
        goToSubmenu(4);
    }
    else{
        goToSubmenu(1);
    }
}

$(document).ready(function(){
   subnavigation();
   subnavigationMobile();
   footerLinks();

   $('.slider').lightSlider({
       mode: 'fade',
       item: 1,
       auto:true,
       pause: 3000,
       slideMargin: 0,
       pager:false,
       loop:true,
       speed: 700
   });
   $('.arrow-down').click(function(){
       $('html, body').stop().animate({
                'scrollTop': $('.home_scroll').offset().top
            }, 500, 'swing', function () {
        });
   });
   $('.animate').smoove({
        offset: '5%',
        perspective: '1000px'
    });

    $('.navbar_btn').click(function(){
        if($(this).hasClass('on')){
            $(this).removeClass('on');
            $('.header').removeClass('expand');
            $('.c-nav-main').slideUp();
            $('.title-menu').fadeIn();
            document.getElementById('navbar_btn').setAttribute('aria-expanded', 'true');
        }
        else{
            $(this).addClass('on');
            $('.header').addClass('expand');
            $('.c-nav-main').slideDown();
            $('.title-menu').hide();
            document.getElementById('navbar_btn').setAttribute('aria-expanded', 'false');

        }
    });
});